import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { eventsApi, ordersApi } from "../../lib/client";
import SpeedBalanceCheck from "./SpeedBalanceCheck";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import CaptchaVerification from "./CaptchaVerification";
import MpCheckout from "../../lib/mp/MpCheckout";
import {
  Receipt,
  Clock,
  CreditCard,
  Tag,
  ShoppingBag,
  AlertCircle,
  Copy,
  ChevronRight,
} from "lucide-react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const CheckOut = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [event, setEvent] = useState(null);
  const [product, setProduct] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("stripe");
  const [balance, setBalance] = useState(0);
  const [isPaying, setIsPaying] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const orderData = await ordersApi.getOrderById(uid);
        setOrder(orderData);

        if (orderData.type === "event") {
          await fetchEvent(orderData.itemId, orderData.categoryId);
        } else if (orderData.type === "product") {
          await fetchProduct(orderData.itemId);
        }
      } catch (error) {
        console.error("Error fetching order:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [uid]);

  const fetchEvent = async (itemId, categoryId) => {
    try {
      const eventData = await eventsApi.getEventById(itemId, {
        categories: true,
      });
      const categoryNameFound = eventData.categories.find(
        (c) => c.id === categoryId
      );
      setEvent(eventData);
      setCategoryName(categoryNameFound?.name || "Category not found");
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  const fetchProduct = async (itemId) => {
    try {
      const productRef = doc(db, "ShopItems", itemId);
      const productSnap = await getDoc(productRef);
      if (productSnap.exists()) {
        setProduct(productSnap.data());
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const handleSupport = () => {
    const eventUrl = window.location.href;
    const whatsappUrl = `https://wa.me/526622995258?text=Hola!%20Necesito%20soporte%20en%20${eventUrl}%20!!!`;
    window.open(whatsappUrl, "_blank");
  };

  const handlePaymentMethodChange = (method) => {
    setSelectedPaymentMethod(method);
    setCaptchaVerified(false);
  };

  const handleBalanceUpdate = (userBalance) => {
    setBalance(userBalance);
  };

  const handleCopyOrderId = () => {
    navigator.clipboard.writeText(uid);
    toast.success("Order ID copiado", {
      icon: "📋",
      style: {
        borderRadius: "10px",
        background: "#333",
        color: "#fff",
      },
    });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-gray-100">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!order || (!event && !product)) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 p-4">
        <AlertCircle className="w-16 h-16 text-red-500 mb-4" />
        <h2 className="text-2xl font-bold text-gray-800">
          Orden no encontrada
        </h2>
        <button
          onClick={() => navigate("/")}
          className="mt-4 px-6 py-2 bg-black text-white rounded-full hover:bg-gray-800 transition-all"
        >
          Volver al inicio
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 py-8 px-4">
      <div className="max-w-2xl mx-auto space-y-6">
        {/* Header */}
        <div className="bg-white rounded-2xl shadow-lg p-6 space-y-4">
          <div className="flex items-center justify-between">
            <h1 className="text-2xl font-bold text-gray-800">
              {order.type === "event" ? event.eventName : product?.title}
            </h1>
            <Tag className="w-6 h-6 text-blue-500" />
          </div>

          {/* Order ID Section */}
          <div className="flex items-center justify-between bg-gray-50 p-4 rounded-xl">
            <div className="flex items-center space-x-2">
              <Receipt className="w-5 h-5 text-gray-500" />
              <span className="text-sm text-gray-600">Order ID:</span>
            </div>
            <button
              onClick={handleCopyOrderId}
              className="flex items-center space-x-2 text-blue-500 hover:text-blue-600 transition-colors"
            >
              <span className="text-sm font-medium">{uid}</span>
              <Copy className="w-4 h-4" />
            </button>
          </div>

          {/* Event/Product Details */}
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600">Tipo:</span>
              <span className="font-medium">
                {order.type === "event" ? "Evento" : "Producto"}
              </span>
            </div>
            {order.type === "event" && (
              <div className="flex items-center justify-between text-sm">
                <span className="text-gray-600">Categoría:</span>
                <span className="font-medium">{categoryName}</span>
              </div>
            )}
            <div className="flex items-center justify-between text-sm">
              <span className="text-gray-600">Estado:</span>
              <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-xs font-medium">
                {order.status || "No definido"}
              </span>
            </div>
          </div>
        </div>

        {/* Order Summary */}
        <div className="bg-white rounded-2xl shadow-lg p-6">
          <div className="flex items-center space-x-2 mb-4">
            <ShoppingBag className="w-5 h-5 text-blue-500" />
            <h2 className="text-xl font-bold text-gray-800">
              Resumen de orden
            </h2>
          </div>

          <div className="space-y-4">
            {/* Show Category Price */}
            {order.type === "event" && (
              <div className="flex justify-between items-center p-3 bg-gray-50 rounded-xl">
                <span className="text-gray-700">{categoryName}</span>
                <span className="font-medium">
                  ${order.categoryPrice?.toFixed(2)}
                </span>
              </div>
            )}

            {/* Addons List */}
            {order.addons?.map((addon, index) => (
              <div
                key={index}
                className="flex flex-col p-3 bg-gray-50 rounded-xl"
              >
                <div className="flex justify-between items-center">
                  <span className="text-gray-700">{addon.name}</span>
                  <span className="font-medium">
                    ${addon.price?.toFixed(2)}
                  </span>
                </div>
                {/* Show variant details if they exist */}
                {addon.selectedVariant && (
                  <div className="mt-1 text-sm text-blue-600">
                    {addon.selectedVariant.variantName}:{" "}
                    {addon.selectedVariant.optionName}
                  </div>
                )}
              </div>
            ))}

            {/* Total */}
            <div className="flex justify-between items-center p-4 bg-black text-white rounded-xl mt-6">
              <span className="font-bold">Total</span>
              <span className="text-xl font-bold">
                ${order.total?.toFixed(2)}
              </span>
            </div>
          </div>
        </div>

        {/* Payment Methods */}
        <div className="bg-white rounded-2xl shadow-lg p-6">
          <div className="flex items-center space-x-2 mb-6">
            <CreditCard className="w-5 h-5 text-blue-500" />
            <h2 className="text-xl font-bold text-gray-800">Método de pago</h2>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-6">
            <button
              onClick={() => handlePaymentMethodChange("stripe")}
              className={`p-4 border-2 rounded-xl transition-all ${
                selectedPaymentMethod === "stripe"
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-200 hover:border-blue-300"
              }`}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/paymentMethods%2Fcredit_card_big.webp?alt=media&token=24fc2a68-6f86-4847-bb13-2f74364947e1"
                alt="Stripe"
                className="w-full h-12 object-contain"
              />
            </button>

            <button
              onClick={() => handlePaymentMethodChange("speedcoins")}
              className={`p-4 border-2 rounded-xl transition-all ${
                selectedPaymentMethod === "speedcoins"
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-200 hover:border-blue-300"
              }`}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2FCOINS%20(1).png?alt=media&token=6197a1b6-7594-4a91-8262-d5688fc2bc0a"
                alt="SpeedCoins"
                className="w-full h-12 object-contain"
              />
            </button>
          </div>

          {/* Payment Components */}
          {selectedPaymentMethod === "speedcoins" && (
            <SpeedBalanceCheck
              userId={order.userId}
              onBalanceUpdate={handleBalanceUpdate}
              orderId={uid}
              orderTotal={order.total}
              isPaying={isPaying}
              setIsPaying={setIsPaying}
            />
          )}

          {selectedPaymentMethod === "stripe" && (
            <div className="mt-4">
              <MpCheckout
                amount={order.total}
                orderId={order.id}
                onFinish={() => navigate(`/success/${order.id}`)}
              />
            </div>
          )}
        </div>

        {/* Support Button */}
        <button
          onClick={handleSupport}
          className="w-full flex items-center justify-center py-4 px-6 bg-green-500 hover:bg-green-600 text-white rounded-xl transition-all"
        >
          <WhatsAppIcon style={{ fontSize: 24, marginRight: 8 }} />
          <span className="font-medium">¿Necesitas ayuda?</span>
        </button>
      </div>
    </div>
  );
};

export default CheckOut;
