import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import {
  Heart,
  MessageSquare,
  Users,
  Share2,
  Bookmark,
  Clock,
  Trophy,
  MapPin,
  ChevronRight,
} from "lucide-react";

const UpcomingEventsBanner = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [userId, setUserId] = useState(null);

  const formatLocation = (location) => {
    if (!location) return "";
    if (typeof location === "string") return location;
    if (location.lat && location.lng) {
      return `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`;
    }
    return "";
  };

  const getTimeRemaining = (eventDate) => {
    const total = eventDate - new Date();
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    return { total, days, hours };
  };

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleDateString("es-ES", options);
  };

  useEffect(() => {
    const fetchEventsWithOrganizers = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);

        const eventsData = await Promise.all(
          eventsSnapshot.docs.map(async (eventDoc) => {
            const eventData = eventDoc.data();
            const organizerId = eventData.creatorUID;

            let organizerData = {
              name: "Organizador Desconocido",
              photoUrl: null,
            };

            if (organizerId) {
              try {
                const organizerDocRef = doc(db, "Users", organizerId);
                const organizerDoc = await getDoc(organizerDocRef);
                if (organizerDoc.exists()) {
                  organizerData = {
                    name: organizerDoc.data().name,
                    photoUrl: organizerDoc.data().photoUrl,
                  };
                }
              } catch (error) {
                console.error("Error fetching organizer data:", error);
              }
            }

            const participantsCollection = collection(
              eventDoc.ref,
              "participants"
            );
            const participantsSnapshot = await getDocs(participantsCollection);
            const participantsCount = participantsSnapshot.size;

            const chatCollection = collection(eventDoc.ref, "chat");
            const chatSnapshot = await getDocs(chatCollection);
            const commentsCount = chatSnapshot.size;

            return {
              id: eventDoc.id,
              ...eventData,
              dateTime: eventData.dateTime
                ? new Date(eventData.dateTime)
                : null,
              organizer: {
                name: organizerData.name,
                photoUrl: organizerData.photoUrl,
              },
              participants: participantsCount,
              comments: commentsCount,
              likes: eventData.likes || [],
              location: eventData.location || null,
            };
          })
        );

        const upcomingEvents = eventsData
          .filter((event) => event.dateTime && event.dateTime > new Date())
          .sort((a, b) => a.dateTime - b.dateTime);

        setEvents(upcomingEvents);
      } catch (error) {
        console.error("Error fetching events with organizers:", error);
      }
    };

    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    fetchEventsWithOrganizers();
    fetchUserId();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleLike = async (eventId, likes) => {
    if (!userId) return;

    const eventRef = doc(db, "events", eventId);
    try {
      if (likes.includes(userId)) {
        await updateDoc(eventRef, {
          likes: arrayRemove(userId),
        });
      } else {
        await updateDoc(eventRef, {
          likes: arrayUnion(userId),
        });
      }
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId
            ? {
                ...event,
                likes: likes.includes(userId)
                  ? likes.filter((id) => id !== userId)
                  : [...likes, userId],
              }
            : event
        )
      );
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto bg-neutral-50 min-h-screen p-4 md:p-6">
      <div className="flex flex-col gap-8">
        {events.map((event) => {
          const timeRemaining = getTimeRemaining(event.dateTime);
          const locationText = formatLocation(event.location);

          return (
            <article
              key={event.id}
              className="bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-xl"
            >
              {/* Event Type Banner */}
              <div className="bg-black text-white px-6 py-3 flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Trophy className="w-5 h-5" />
                  <span className="font-medium tracking-wide">Evento</span>
                </div>
                {timeRemaining.total > 0 && (
                  <div className="flex items-center gap-2">
                    <Clock className="w-4 h-4" />
                    <span className="text-sm font-medium">
                      {timeRemaining.days > 0 ? `${timeRemaining.days}d ` : ""}
                      {timeRemaining.hours}h restantes
                    </span>
                  </div>
                )}
              </div>

              {/* Header */}
              <div className="flex items-center justify-between px-6 py-4">
                <div className="flex items-center gap-4">
                  <div className="w-14 h-14 rounded-full bg-gradient-to-tr from-neutral-900 to-neutral-700 p-0.5">
                    {event.organizer.photoUrl ? (
                      <img
                        src={event.organizer.photoUrl}
                        alt={event.organizer.name}
                        className="w-full h-full object-cover rounded-full border-2 border-white"
                      />
                    ) : (
                      <div className="w-full h-full rounded-full bg-neutral-800 flex items-center justify-center text-white font-medium">
                        {event.organizer.name.substring(0, 2)}
                      </div>
                    )}
                  </div>
                  <div>
                    <p className="font-semibold text-lg text-neutral-900">
                      {event.organizer.name}
                    </p>
                    <p className="text-sm text-neutral-500">Organizador</p>
                  </div>
                </div>
                <button className="text-neutral-700 hover:text-neutral-900 transition-colors">
                  <Share2 className="w-6 h-6" />
                </button>
              </div>

              {/* Image */}
              <div
                className="relative aspect-video cursor-pointer"
                onClick={() => handleEventClick(event.id)}
              >
                <img
                  src={event.photo}
                  alt={event.eventName}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/30"></div>
              </div>

              {/* Date and Location */}
              <div className="px-6 py-4 space-y-3">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-3">
                    <Clock className="w-5 h-5 text-neutral-700" />
                    <span className="text-sm font-medium text-neutral-800">
                      {formatDate(event.dateTime)}
                    </span>
                  </div>
                  {locationText && (
                    <div className="flex items-center gap-3">
                      <MapPin className="w-5 h-5 text-neutral-700" />
                      <span className="text-sm text-neutral-600">
                        {locationText}
                      </span>
                    </div>
                  )}
                </div>

                {/* Event Title and Description */}
                <div className="space-y-2">
                  <h3 className="text-xl font-bold text-neutral-900">
                    {event.eventName}
                  </h3>
                  {event.description && (
                    <p className="text-neutral-600 text-sm line-clamp-2">
                      {event.description}
                    </p>
                  )}
                </div>

                {/* Stats and Actions */}
                <div className="flex items-center justify-between pt-2">
                  <div className="flex items-center gap-6">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(event.id, event.likes);
                      }}
                      className="flex items-center gap-2 group"
                    >
                      <Heart
                        className={`w-6 h-6 transition-colors ${
                          event.likes.includes(userId)
                            ? "fill-red-500 text-red-500"
                            : "text-neutral-700 group-hover:text-neutral-900"
                        }`}
                      />
                      <span className="text-sm font-medium text-neutral-700">
                        {event.likes.length}
                      </span>
                    </button>
                    <button className="flex items-center gap-2 group">
                      <MessageSquare className="w-6 h-6 text-neutral-700 group-hover:text-neutral-900" />
                      <span className="text-sm font-medium text-neutral-700">
                        {event.comments}
                      </span>
                    </button>
                    <button className="flex items-center gap-2 group">
                      <Users className="w-6 h-6 text-neutral-700 group-hover:text-neutral-900" />
                      <span className="text-sm font-medium text-neutral-700">
                        {event.participants}
                      </span>
                    </button>
                  </div>
                  <button className="text-neutral-700 hover:text-neutral-900 transition-colors">
                    <Bookmark className="w-6 h-6" />
                  </button>
                </div>
              </div>

              {/* CTA Button */}
              <div className="px-6 pb-6">
                <button
                  onClick={() => handleEventClick(event.id)}
                  className="w-full bg-black text-white rounded-xl py-4 px-6 text-sm font-semibold flex items-center justify-center gap-2 transition-all hover:bg-neutral-800 focus:ring-2 focus:ring-neutral-900 focus:ring-offset-2"
                >
                  <span>Participar en el evento</span>
                  <ChevronRight className="w-4 h-4" />
                </button>
              </div>
            </article>
          );
        })}
      </div>
    </div>
  );
};

export default UpcomingEventsBanner;
