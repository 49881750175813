import React from "react";
import { Ticket, Timer } from "lucide-react";

const SummaryStep = ({
  event,
  selectedCategory,
  selectedAddons,
  addonSizes,
  totalPrice,
  customPrice
}) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  return (
    <div className="max-w-md mx-auto">
      {/* Ticket Container */}
      <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
        {/* Ticket Header */}
        <div className="bg-black text-white p-6 text-center relative">
          <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500" />
          <Ticket className="w-8 h-8 mx-auto mb-3" />
          <h2 className="text-2xl font-bold">{event.eventName}</h2>
        </div>

        {/* Zigzag separator */}
        <div className="h-4 bg-black relative">
          <div className="absolute left-0 right-0 h-4">
            <div className="absolute left-0 right-0 bottom-0">
              <svg className="w-full h-4 text-white" viewBox="0 0 100 10" preserveAspectRatio="none">
                <path d="M0 10 L10 0 L20 10 L30 0 L40 10 L50 0 L60 10 L70 0 L80 10 L90 0 L100 10 Z" fill="currentColor"/>
              </svg>
            </div>
          </div>
        </div>

        {/* Ticket Content */}
        <div className="p-6 space-y-6">
          {/* Category Section */}
          <div className="space-y-2">
            <div className="text-gray-500 text-sm font-medium">Categoría</div>
            <div className="flex justify-between items-center">
              <span className="text-lg font-semibold">{selectedCategory?.name}</span>
              <span className="text-lg font-bold">
                {formatPrice(customPrice ? selectedCategory?.price : event.price)}
              </span>
            </div>
          </div>

          {/* Separator */}
          <div className="border-t border-dashed" />

          {/* Addons Section */}
          {selectedAddons.length > 0 && (
            <div className="space-y-3">
              <div className="text-gray-500 text-sm font-medium">Add-ons</div>
              {selectedAddons.map((addon) => (
                <div key={addon.id} className="flex justify-between items-center">
                  <div>
                    <div className="font-medium">{addon.name}</div>
                    {addonSizes[addon.id] && (
                      <div className="text-sm text-gray-500">
                        Talla: {addonSizes[addon.id]}
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    {addon.discount ? (
                      <>
                        <div className="text-sm text-gray-400 line-through">
                          {formatPrice(addon.price)}
                        </div>
                        <div className="font-bold text-green-600">
                          {formatPrice(addon.price - addon.discount)}
                        </div>
                      </>
                    ) : (
                      <div className="font-bold">
                        {formatPrice(addon.price)}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Separator */}
          <div className="border-t-2 border-dashed" />

          {/* Total Section */}
          <div className="flex justify-between items-center text-xl font-bold">
            <span>Total</span>
            <span className="text-blue-600">{formatPrice(totalPrice)}</span>
          </div>

          {/* Payment Info */}
          {totalPrice > 0 && (
            <div className="bg-blue-50 rounded-xl p-4 flex items-center space-x-2 text-blue-600">
              <Timer className="w-5 h-5 flex-shrink-0" />
              <p className="text-sm font-medium">
                Tienes 24 horas para completar tu pago
              </p>
            </div>
          )}
        </div>

        {/* Ticket Footer */}
        <div className="bg-gray-50 p-4 text-center text-sm text-gray-500">
          <p>ID: {event.id}</p>
        </div>
      </div>
    </div>
  );
};

export default SummaryStep;