import React, { useEffect, useState, useRef } from "react";
import {
  doc,
  collection,
  addDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db, storage, auth } from "../../firebase";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { 
  Camera, 
  Send, 
  Trash2,
  Loader2,
  Check,
  X,
  MessageSquare,
  Image as ImageIcon,
} from "lucide-react";

const EventChat = ({ uid }) => {
  const messagesEndRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");
  const [userId, setUserId] = useState(null);
  const [typing, setTyping] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [loading, setLoading] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      const chatRef = collection(db, "events", uid, "chat");
      const q = query(chatRef, orderBy("timestamp", "asc"));
      
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const updatedMessages = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const userInfo = await fetchUserInfo(data.senderId);
            return { 
              id: doc.id, 
              ...data, 
              userInfo,
              imageUrl: data.imageUrl || null 
            };
          })
        );
        setMessages(updatedMessages);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    const listenToTypingStatus = () => {
      const typingRef = doc(db, "events", uid, "typing", "status");
      const unsubscribe = onSnapshot(typingRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIsUserTyping(data.isTyping && data.userId !== userId);
        }
      });

      return () => unsubscribe();
    };

    fetchMessages();
    fetchUserId();
    listenToTypingStatus();
  }, [uid, userId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" && !selectedImage) return;

    let imageUrl = "";
    if (selectedImage) {
      try {
        const imageRef = ref(storage, `chat_images/${uid}/${Date.now()}_${selectedImage.name}`);
        const snapshot = await uploadBytes(imageRef, selectedImage);
        imageUrl = await getDownloadURL(snapshot.ref);
      } catch (error) {
        console.error("Error uploading image:", error);
        return;
      }
    }

    try {
      const chatRef = collection(db, "events", uid, "chat");
      const messageData = {
        message: newMessage.trim(),
        senderId: userId,
        timestamp: serverTimestamp(),
        imageUrl: imageUrl || null,
      };

      const docRef = await addDoc(chatRef, messageData);
      await updateDoc(docRef, { sent: true });

      setNewMessage("");
      setSelectedImage(null);
      setPreviewImage(null);
      setTyping(false);

      const typingRef = doc(db, "events", uid, "typing", "status");
      await setDoc(typingRef, { isTyping: false, userId });
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleTyping = async (e) => {
    setNewMessage(e.target.value);
    if (!typing) {
      setTyping(true);
      const typingRef = doc(db, "events", uid, "typing", "status");
      await setDoc(typingRef, { isTyping: true, userId });
    }
  };

  const handleStopTyping = async () => {
    setTyping(false);
    const typingRef = doc(db, "events", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: false, userId });
  };

  const handleDeleteMessage = async () => {
    if (!messageToDelete) return;

    try {
      if (messageToDelete.imageUrl) {
        const imageRef = ref(storage, messageToDelete.imageUrl);
        await deleteObject(imageRef).catch(error => {
          console.error("Error deleting image:", error);
        });
      }

      const messageRef = doc(db, "events", uid, "chat", messageToDelete.id);
      await deleteDoc(messageRef);
      setMessageToDelete(null);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const fetchUserInfo = async (userId) => {
    try {
      const userRef = doc(db, "Users", userId);
      const userSnap = await getDoc(userRef);
      return userSnap.exists() ? userSnap.data() : { name: "Unknown", photoUrl: "" };
    } catch (error) {
      console.error("Error fetching user info:", error);
      return { name: "Unknown", photoUrl: "" };
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("Image size should be less than 5MB");
        return;
      }
      setSelectedImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleImageClick = (url, e) => {
    e.stopPropagation();
    setLargeImageUrl(url);
    setOpenImageDialog(true);
  };

  return (
    <div className="flex flex-col h-screen md:h-[80vh] w-full max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
      {/* Header */}
      <div className="flex items-center justify-between bg-black text-white p-4">
        <div className="flex items-center space-x-2">
          <MessageSquare className="w-6 h-6" />
          <h1 className="text-xl font-semibold">Chat</h1>
        </div>
        {isUserTyping && (
          <span className="text-sm animate-pulse">Alguien está escribiendo...</span>
        )}
      </div>

      {/* Messages Container */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4 bg-gray-50">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <Loader2 className="w-8 h-8 animate-spin text-gray-500" />
          </div>
        ) : (
          <>
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.senderId === userId ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  onClick={() => message.senderId === userId && setMessageToDelete(message)}
                  className={`group max-w-[85%] md:max-w-[70%] rounded-lg shadow-sm hover:shadow-md transition-all
                    ${message.senderId === userId ? "bg-black text-white" : "bg-white"}
                    ${message.senderId === userId ? "cursor-pointer" : ""}
                  `}
                >
                  <div className="p-3">
                    <div className="flex items-center space-x-2">
                      <img
                        src={message.userInfo?.photoUrl || "/api/placeholder/32/32"}
                        alt={message.userInfo?.name}
                        className="w-8 h-8 rounded-full object-cover bg-gray-200"
                      />
                      <span className="font-medium text-sm">
                        {message.userInfo?.name}
                      </span>
                      {message.senderId === userId && (
                        <Trash2 className="w-4 h-4 text-red-500 opacity-0 group-hover:opacity-100 transition-opacity" />
                      )}
                    </div>
                    
                    {message.message && (
                      <p className="mt-2 text-sm md:text-base break-words">
                        {message.message}
                      </p>
                    )}
                    
                    {message.imageUrl && (
                      <div 
                        className="mt-2 relative group/image"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <img
                          src={message.imageUrl}
                          alt="chat"
                          className="max-w-[200px] h-auto rounded-lg cursor-zoom-in transition-transform hover:scale-105"
                          onClick={(e) => handleImageClick(message.imageUrl, e)}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/api/placeholder/200/200";
                          }}
                        />
                        <div className="absolute inset-0 bg-black bg-opacity-0 group-hover/image:bg-opacity-20 transition-all rounded-lg flex items-center justify-center">
                          <ImageIcon className="w-6 h-6 text-white opacity-0 group-hover/image:opacity-100 transition-opacity" />
                        </div>
                      </div>
                    )}
                    
                    <div className="mt-2 flex items-center justify-between text-xs text-gray-500">
                      <span>
                        {new Date(message.timestamp?.seconds * 1000).toLocaleString()}
                      </span>
                      {message.senderId === userId && message.sent && (
                        <Check className="w-4 h-4 text-green-500 ml-2" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </>
        )}
      </div>

      {/* Input Area */}
      <div className="border-t bg-white p-4">
        {previewImage && (
          <div className="mb-2 relative inline-block">
            <img
              src={previewImage}
              alt="Preview"
              className="w-20 h-20 object-cover rounded-lg"
            />
            <button
              onClick={() => {
                setSelectedImage(null);
                setPreviewImage(null);
              }}
              className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
            >
              <X className="w-4 h-4" />
            </button>
          </div>
        )}
        
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Escribe un mensaje..."
            value={newMessage}
            onChange={handleTyping}
            onBlur={handleStopTyping}
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            className="flex-1 bg-gray-100 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-black"
          />
          
          <label className="cursor-pointer">
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleImageChange}
            />
            <Camera className="w-6 h-6 text-gray-500 hover:text-black transition-colors" />
          </label>
          
          <button
            onClick={handleSendMessage}
            disabled={!newMessage.trim() && !selectedImage}
            className="bg-black text-white p-2 rounded-full hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="w-5 h-5" />
          </button>
        </div>
      </div>

      {/* Delete Message Dialog */}
      {messageToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-lg font-semibold mb-2">Eliminar Mensaje</h3>
            <p className="text-gray-600 mb-4">
              ¿Estás seguro de que quieres eliminar este mensaje? Esta acción no se puede deshacer.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setMessageToDelete(null)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              >
                Cancelar
              </button>
              <button
                onClick={handleDeleteMessage}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Image Preview Dialog */}
      {openImageDialog && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
          onClick={() => setOpenImageDialog(false)}
        >
          <div className="max-w-3xl max-h-[90vh]">
            <img
              src={largeImageUrl}
              alt="Full size"
              className="max-w-full max-h-full object-contain rounded-lg"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/api/placeholder/400/400";
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventChat;