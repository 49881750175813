import React from "react";
import { CheckCircle, Tag, Package, AlertCircle } from "lucide-react";

const AddonsStep = ({
  addons,
  selectedAddons,
  addonSizes,
  onAddonChange,
  onSizeChange,
}) => {
  const handleVariantChange = (addon, variant, optionName) => {
    // Incluir el nombre y precio de la opción seleccionada
    const selectedVariantOption = variant.options.find(opt => opt.name === optionName);
    const variantData = {
      variantName: variant.name,
      optionName: optionName,
      optionPrice: selectedVariantOption?.price || 0
    };

    onSizeChange(addon.id, optionName);
    
    // Si el addon no está seleccionado, seleccionarlo con la variante
    if (!selectedAddons.some(a => a.id === addon.id)) {
      onAddonChange(addon, true, variantData);
    }
  };

  const handleAddonSelect = (addon) => {
    if (addon.variants?.length > 0) {
      // Si tiene variantes, requerir selección primero
      return;
    }
    onAddonChange(addon, !selectedAddons.some(a => a.id === addon.id));
  };

  const getAddonPrice = (addon, selectedOption) => {
    if (addon.variants && selectedOption) {
      const variantOption = addon.variants
        .flatMap((variant) => variant.options)
        .find((option) => option.name === selectedOption);

      const basePrice = addon.price + (variantOption?.price || 0);
      const finalPrice = addon.discount ? basePrice - addon.discount : basePrice;
      return { basePrice, finalPrice };
    }
    const finalPrice = addon.discount ? addon.price - addon.discount : addon.price;
    return { basePrice: addon.price, finalPrice };
  };

  if (addons.length === 0) {
    return (
      <div className="text-center p-6 bg-gray-50 rounded-xl">
        <Package className="w-12 h-12 mx-auto text-gray-400 mb-2" />
        <p className="text-gray-600">No hay complementos disponibles</p>
      </div>
    );
  }

  const getVariantDetails = (addon) => {
    if (!addon.variants || !addonSizes[addon.id]) return null;
    const selectedOptionName = addonSizes[addon.id];
    const variant = addon.variants.find(v => 
      v.options.some(opt => opt.name === selectedOptionName)
    );
    const option = variant?.options.find(opt => opt.name === selectedOptionName);
    
    return option ? `${variant.name}: ${option.name}` : null;
  };

  return (
    <div className="space-y-4">
      {/* Header explicativo */}
      <div className="bg-gray-50 p-4 rounded-xl mb-6">
        <h3 className="text-lg font-semibold mb-2 flex items-center">
          <Package className="w-5 h-5 mr-2 text-blue-600" />
          Complementos disponibles
        </h3>
        <p className="text-sm text-gray-600">
          {addons.some(addon => addon.variants?.length > 0) 
            ? "Selecciona las opciones para cada complemento"
            : "Toca una tarjeta para seleccionar un complemento"}
        </p>
      </div>

      {/* Lista de addons */}
      <div className="space-y-4">
        {addons.map((addon) => {
          const isSelected = selectedAddons.some((a) => a.id === addon.id);
          const selectedOption = addonSizes[addon.id];
          const { basePrice, finalPrice } = getAddonPrice(addon, selectedOption);
          const hasVariants = addon.variants?.length > 0;
          const variantDetails = getVariantDetails(addon);

          return (
            <div
              key={addon.id}
              className={`relative bg-white rounded-xl transition-all duration-300 ${
                isSelected 
                  ? "border-2 border-blue-500 shadow-lg" 
                  : "border border-gray-200"
              }`}
            >
              {/* Card principal */}
              <div
                onClick={() => !hasVariants && handleAddonSelect(addon)}
                className={`p-4 ${!hasVariants ? "cursor-pointer" : ""}`}
              >
                <div className="flex items-start gap-4">
                  {/* Imagen del addon */}
                  {addon.image && (
                    <img
                      src={addon.image}
                      alt={addon.name}
                      className="w-20 h-20 object-cover rounded-lg flex-shrink-0"
                    />
                  )}

                  {/* Contenido principal */}
                  <div className="flex-1">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {addon.name}
                        </h3>
                        {hasVariants && !selectedOption && (
                          <p className="text-sm text-blue-600 flex items-center mt-1">
                            <AlertCircle className="w-4 h-4 mr-1" />
                            Selecciona una opción
                          </p>
                        )}
                        {variantDetails && (
                          <p className="text-sm text-green-600 mt-1">
                            {variantDetails}
                          </p>
                        )}
                      </div>
                      {!hasVariants && (
                        isSelected ? (
                          <CheckCircle className="w-6 h-6 text-blue-500" />
                        ) : (
                          <div className="w-6 h-6 rounded-full border-2 border-gray-300" />
                        )
                      )}
                    </div>

                    {/* Precios */}
                    <div className="mt-2">
                      {addon.discount ? (
                        <div className="flex items-center gap-2">
                          <Tag className="w-4 h-4 text-red-500" />
                          <span className="text-sm text-gray-400 line-through">
                            ${basePrice}
                          </span>
                          <span className="font-bold text-red-600">
                            ${finalPrice}
                          </span>
                        </div>
                      ) : (
                        <span className="font-semibold text-gray-700">
                          ${finalPrice}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* Variantes como círculos seleccionables */}
              {addon.variants && (
                <div className="border-t border-gray-100 p-4 bg-gray-50 rounded-b-xl">
                  {addon.variants.map((variant) => (
                    <div key={variant.name} className="mt-2 first:mt-0">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        {variant.name}
                      </label>
                      <div className="flex flex-wrap gap-2">
                        {variant.options.map((option) => {
                          const isOptionSelected = selectedOption === option.name;
                          return (
                            <button
                              key={option.name}
                              onClick={() => handleVariantChange(addon, variant, option.name)}
                              className={`px-4 py-2 rounded-full text-sm font-medium transition-all
                                ${isOptionSelected 
                                  ? "bg-blue-500 text-white ring-2 ring-blue-300" 
                                  : "bg-white border border-gray-300 text-gray-700 hover:border-blue-500"
                                }
                              `}
                            >
                              {option.name}
                              {option.price > 0 && ` (+$${option.price})`}
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Resumen de selección */}
      {selectedAddons.length > 0 && (
        <div className="mt-6 p-4 bg-blue-50 rounded-xl">
          <p className="text-sm font-medium text-blue-700">
            {selectedAddons.length} complemento{selectedAddons.length !== 1 ? 's' : ''} seleccionado{selectedAddons.length !== 1 ? 's' : ''}
          </p>
          {/* Mostrar detalles de las variantes seleccionadas */}
          <div className="mt-2 space-y-1">
            {selectedAddons.map(addon => {
              const variantDetails = getVariantDetails(addon);
              return variantDetails && (
                <p key={addon.id} className="text-sm text-gray-600">
                  {addon.name}: {variantDetails}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddonsStep;