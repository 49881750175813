import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Search,
  Calendar,
  User,
  DollarSign,
  Coffee,
  MapPin,
  Clock,
  Trophy,
  Info,
  Loader2,
  X,
} from "lucide-react";

const EventHome = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const filterParam = query.get("filter");
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("upcoming");

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
        }));
        const sortedEvents = eventsData.sort((a, b) => b.dateTime - a.dateTime);
        setEvents(sortedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    if (filterParam) {
      setFilter(filterParam);
    }
    fetchEvents();
  }, [filterParam]);

  const handleEventClick = (eventId) => navigate(`/event/${eventId}`);
  const handleResultsClick = (eventId) => navigate(`/leaderboard/${eventId}`);

  const isEventPast = (eventDate) => eventDate < new Date();
  const isEventToday = (eventDate) =>
    eventDate.toDateString() === new Date().toDateString();

  const formatDate = (date) =>
    new Intl.DateTimeFormat("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const getCountdown = (eventDate) => {
    const diff = eventDate - new Date();
    if (diff <= 0) return null;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return { days, hours, minutes };
  };

  const getLocationString = (location) => {
    if (!location) return null;
    if (typeof location === "string") return location;
    if (location.address) return location.address;
    if (location.lat && location.lng)
      return `${location.lat.toFixed(6)}, ${location.lng.toFixed(6)}`;
    return null;
  };

  const filteredEvents = events
    .filter((event) => !event.invisible)
    .filter((event) =>
      filter === "upcoming"
        ? !isEventPast(event.dateTime) && !isEventToday(event.dateTime)
        : filter === "ongoing"
        ? isEventToday(event.dateTime)
        : isEventPast(event.dateTime)
    )
    .filter((event) => event.eventName.toLowerCase().includes(searchTerm))
    .sort((a, b) =>
      filter === "upcoming" ? a.dateTime - b.dateTime : b.dateTime - a.dateTime
    );

  const FilterButton = ({ label, activeFilter, filterValue, icon: Icon }) => (
    <button
      onClick={() => handleFilterChange(filterValue)}
      className={`flex items-center px-6 py-3 rounded-xl font-medium transition-all duration-300 ${
        activeFilter === filterValue
          ? "bg-black text-white shadow-lg transform hover:scale-105"
          : "bg-gray-100 text-gray-600 hover:bg-gray-200"
      }`}
    >
      <Icon className="mr-2" size={18} />
      {label}
    </button>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="container mx-auto px-4 py-8">
        {/* Header Section */}
        <div className="text-center space-y-4 mb-12">
          <h1 className="text-4xl font-bold text-gray-900">
            Descubre Eventos Increíbles
          </h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Explora los mejores eventos deportivos, únete a la comunidad y
            compite con los mejores
          </p>
        </div>

        {/* Search and Filter Section */}
        <div className="max-w-4xl mx-auto mb-12 space-y-6">
          {/* Search Bar */}
          <div className="relative">
            <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
              <Search className="text-gray-400" size={20} />
            </div>
            {searchTerm && (
              <button
                onClick={() => setSearchTerm("")}
                className="absolute inset-y-0 right-4 flex items-center"
              >
                <X className="text-gray-400 hover:text-gray-600" size={20} />
              </button>
            )}
            <input
              type="text"
              placeholder="Buscar eventos por nombre..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full pl-12 pr-12 py-4 rounded-2xl border border-gray-200 focus:ring-2 focus:ring-black focus:border-transparent outline-none transition-all duration-300 bg-white shadow-sm text-gray-900"
            />
          </div>

          {/* Filter Pills */}
          <div className="flex flex-wrap gap-4 justify-center">
            <FilterButton
              label="Próximos"
              activeFilter={filter}
              filterValue="upcoming"
              icon={Calendar}
            />
            <FilterButton
              label="En Curso"
              activeFilter={filter}
              filterValue="ongoing"
              icon={Clock}
            />
            <FilterButton
              label="Finalizados"
              activeFilter={filter}
              filterValue="past"
              icon={Trophy}
            />
          </div>
        </div>

        {/* Events Grid */}
        {loading ? (
          <div className="flex items-center justify-center min-h-[400px]">
            <Loader2 className="w-12 h-12 animate-spin text-gray-900" />
          </div>
        ) : filteredEvents.length === 0 ? (
          <div className="text-center py-12">
            <div className="bg-white rounded-2xl p-8 max-w-md mx-auto shadow-lg">
              <Info className="w-16 h-16 mx-auto mb-4 text-gray-400" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">
                No se encontraron eventos
              </h3>
              <p className="text-gray-600">
                Intenta ajustar los filtros o realizar una nueva búsqueda
              </p>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredEvents.map((event) => {
              const isPast = isEventPast(event.dateTime);
              const isToday = isEventToday(event.dateTime);
              const countdown = getCountdown(event.dateTime);
              const locationString = getLocationString(event.location);

              return (
                <div
                  key={event.id}
                  className="group bg-white rounded-2xl shadow-lg overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:-translate-y-1"
                >
                  {/* Image Container */}
                  <div className="relative h-64 overflow-hidden">
                    <img
                      src={event.photo}
                      alt={event.eventName}
                      className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />

                    {/* Event Status Badge */}
                    <div className="absolute top-4 right-4">
                      {isPast ? (
                        <span className="px-4 py-2 rounded-full bg-gray-900/80 text-white text-sm font-medium">
                          Finalizado
                        </span>
                      ) : isToday ? (
                        <span className="px-4 py-2 rounded-full bg-green-500/80 text-white text-sm font-medium animate-pulse">
                          En curso
                        </span>
                      ) : countdown ? (
                        <div className="px-4 py-2 rounded-full bg-black/80 text-white text-sm font-medium">
                          {`${countdown.days}d ${countdown.hours}h ${countdown.minutes}m`}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* Content */}
                  <div className="p-6 space-y-4">
                    <h3 className="text-xl font-bold text-gray-900 line-clamp-2">
                      {event.eventName}
                    </h3>

                    <div className="space-y-2 text-gray-600">
                      <div className="flex items-center gap-2">
                        <Calendar className="w-4 h-4 flex-shrink-0" />
                        <span className="text-sm">
                          {formatDate(event.dateTime)}
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <User className="w-4 h-4 flex-shrink-0" />
                        <span className="text-sm">{event.organization}</span>
                      </div>
                      {locationString && (
                        <div className="flex items-center gap-2">
                          <MapPin className="w-4 h-4 flex-shrink-0" />
                          <span
                            className="text-sm truncate"
                            title={locationString}
                          >
                            {locationString}
                          </span>
                        </div>
                      )}
                    </div>

                    {/* Price Tag */}
                    <div className="flex items-center gap-2">
                      <span
                        className={`px-4 py-2 rounded-full text-sm font-medium ${
                          event.price > 0
                            ? "bg-gray-100 text-gray-900"
                            : "bg-green-100 text-green-700"
                        }`}
                      >
                        {event.price > 0 ? (
                          <span className="flex items-center">
                            <DollarSign className="w-4 h-4 mr-1" />
                            {event.price}
                          </span>
                        ) : (
                          <span className="flex items-center">
                            <Coffee className="w-4 h-4 mr-1" />
                            Gratuito
                          </span>
                        )}
                      </span>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex gap-3 pt-2">
                      {(isPast || isToday) && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleResultsClick(event.id);
                          }}
                          className="flex-1 px-4 py-2 rounded-xl bg-gray-100 text-gray-900 font-medium hover:bg-gray-200 transition-colors duration-300"
                        >
                          <span className="flex items-center justify-center gap-2">
                            <Trophy className="w-4 h-4" />
                            Resultados
                          </span>
                        </button>
                      )}
                      <button
                        onClick={() => handleEventClick(event.id)}
                        className="flex-1 px-4 py-2 rounded-xl bg-black text-white font-medium hover:bg-gray-800 transition-colors duration-300"
                      >
                        <span className="flex items-center justify-center gap-2">
                          {isPast ? (
                            <>
                              <Info className="w-4 h-4" />
                              Detalles
                            </>
                          ) : (
                            <>
                              <Calendar className="w-4 h-4" />
                              Inscribirme
                            </>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventHome;
