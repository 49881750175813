import React, { useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import { Camera, Trash2, PlusCircle, MapPin } from "lucide-react";
import EventAddons from "./EventAddons";
import FormInput from "../Forms/FormInput";
import toast from "react-hot-toast";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import * as Yup from "yup";
import { eventsApi } from "../../lib/client";
import { useNavigate } from "react-router-dom";

const eventsSchema = Yup.object().shape({
  eventName: Yup.string().required("El nombre es requerido"),
  description: Yup.string().required("La descripción es requerida"),
  dateTime: Yup.string().required("La fecha y hora son requeridas"),
  customPrice: Yup.boolean(),
  price: Yup.number().when('customPrice', {
    is: false,
    then: () => Yup.number().required("El precio es requerido").min(0, "El precio debe ser mayor o igual a 0"),
    otherwise: () => Yup.number().nullable()
  }),
  categories: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("El nombre de la categoría es requerido"),
      price: Yup.number().when('$customPrice', {
        is: true,
        then: () => Yup.number().required("El precio es requerido").min(0, "El precio debe ser mayor o igual a 0"),
        otherwise: () => Yup.number().nullable()
      })
    })
  ),
  location: Yup.object().nullable(),
  photo: Yup.mixed()
});

function EventsForm({ creatorUID, event }) {
  const navigate = useNavigate();
  const [addLocation, setAddLocation] = useState(event?.location ? true : false);
  const [imageError, setImageError] = useState("");

  const initialValues = {
    creatorUID: event?.creatorUID || creatorUID,
    eventName: event?.eventName || "",
    description: event?.description || "",
    dateTime: event?.dateTime || "",
    customPrice: event?.customPrice || false,
    price: event?.price || "",
    location: event?.location || { lat: 29.110262, lng: -110.940463 },
    photo: event?.photo || "",
    categories: event?.categories?.map(cat => ({
      name: cat.name || "",
      price: cat.price?.toString() || ""
    })) || [],
    addons: event?.addons || [],
  };

  const handleAddLocation = (e, setFieldValue) => {
    setAddLocation(e.target.checked);
    setFieldValue("location", e.target.checked ? 
      { lat: 29.110262, lng: -110.940463 } : null
    );
  };

  const handleImageUpload = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5000000) {
        setImageError("La imagen no debe exceder 5MB");
        return;
      }
      setImageError("");
      setFieldValue("photo", file);
    }
  };

  const handleSubmit = async (values) => {
    try {
      // Procesar las categorías asegurando que cada una tenga su precio
      const processedCategories = values.categories
        .filter(cat => cat.name.trim() !== "")
        .map(category => {
          const categoryPrice = values.customPrice ? 
            Number(category.price || 0) : 
            Number(values.price || 0);

          return {
            name: category.name.trim(),
            price: categoryPrice // Asegurar que el precio esté presente
          };
        });

      // Preparar los datos del evento
      const eventData = {
        creatorUID: values.creatorUID,
        eventName: values.eventName,
        description: values.description,
        dateTime: values.dateTime,
        photo: values.photo,
        location: addLocation ? values.location : null,
        customPrice: values.customPrice,
        price: values.customPrice ? null : Number(values.price || 0),
        addons: values.addons,
        categories: processedCategories
      };

      // Verificar que todas las categorías tengan precio válido
      if (processedCategories.some(cat => cat.price === undefined || isNaN(cat.price))) {
        throw new Error("Algunas categorías no tienen precio válido");
      }

      // Crear o actualizar el evento en Firebase
      if (event) {
        await eventsApi.updateEvent(event.id, eventData);
      } else {
        await eventsApi.createEvent(eventData);
      }
      
      toast.success(`Evento ${event ? "actualizado" : "creado"} con éxito`);
      navigate("/my-events");
    } catch (error) {
      toast.error(`Ocurrió un error al ${event ? "actualizar" : "crear"} el evento`);
      console.error(error);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <Formik
        initialValues={initialValues}
        validationSchema={eventsSchema}
        onSubmit={handleSubmit}
        validateOnMount={true}
        validationContext={{ customPrice: initialValues.customPrice }}
      >
        {({ values, setFieldValue, isValid, isSubmitting, errors, touched }) => (
          <Form className="space-y-6">
            {/* Foto del Evento */}
            <div className="bg-white shadow-lg rounded-xl overflow-hidden">
              <div className="p-6 border-b border-gray-100">
                <div className="flex justify-between items-center">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Foto/Flyer del Evento
                  </h2>
                  <div className="relative">
                    <input
                      type="file"
                      accept="image/*"
                      id="photo"
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer z-10"
                      onChange={(e) => handleImageUpload(e, setFieldValue)}
                    />
                    <button
                      type="button"
                      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center gap-2 transition-all duration-200 ease-in-out transform hover:scale-105"
                    >
                      <Camera className="w-5 h-5" />
                      <span className="text-sm font-medium">Subir imagen</span>
                    </button>
                  </div>
                </div>
              </div>

              {imageError && (
                <div className="px-6 py-3 bg-red-50 text-red-600 text-sm font-medium">
                  {imageError}
                </div>
              )}

              {values.photo && (
                <div className="p-6">
                  <div className="aspect-video relative rounded-xl overflow-hidden bg-gray-50">
                    <img
                      src={event ? values.photo : URL.createObjectURL(values.photo)}
                      alt="Vista previa"
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <button
                    type="button"
                    className="mt-4 w-full py-2 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100 transition-all duration-200 flex items-center justify-center gap-2"
                    onClick={() => {
                      setFieldValue("photo", "");
                      setImageError("");
                    }}
                  >
                    <Trash2 className="w-4 h-4" />
                    <span>Eliminar imagen</span>
                  </button>
                </div>
              )}
            </div>

            {/* Detalles básicos */}
            <div className="bg-white shadow-lg rounded-xl p-6 space-y-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Detalles del Evento
              </h2>
              <FormInput
                name="eventName"
                label="Nombre del evento"
                className="w-full"
              />
              <FormInput
                name="description"
                label="Descripción"
                multiline
                rows={4}
                className="w-full"
              />
              <FormInput
                name="dateTime"
                label="Fecha y Hora"
                type="datetime-local"
                className="w-full"
                InputLabelProps={{ shrink: true }}
              />
            </div>

            {/* Precios y Categorías */}
            <div className="bg-white shadow-lg rounded-xl p-6">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                <div>
                  <h2 className="text-xl font-semibold text-gray-800 mb-2">
                    Precios y Categorías
                  </h2>
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-600">
                      Precios por categoría
                    </span>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={values.customPrice}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          setFieldValue("customPrice", checked);
                          if (!checked) {
                            // Si se desactiva customPrice, usar el precio general
                            values.categories.forEach((_, index) => {
                              setFieldValue(`categories.${index}.price`, values.price || "");
                            });
                          } else {
                            // Si se activa customPrice, inicializar precios individuales
                            setFieldValue("price", "");
                            values.categories.forEach((_, index) => {
                              setFieldValue(`categories.${index}.price`, "0");
                            });
                          }
                        }}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const categories = values.categories || [];
                    if (categories.length < 999) {
                      setFieldValue("categories", [
                        ...categories,
                        { 
                          name: "", 
                          price: values.customPrice ? "0" : (values.price || "")
                        },
                      ]);
                    } else {
                      toast.error("Máximo 10 categorías permitidas");
                    }
                  }}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-all duration-200 flex items-center gap-2 hover:scale-105"
                >
                  <PlusCircle className="w-5 h-5" />
                  <span className="font-medium">Agregar Categoría</span>
                </button>
              </div>

              {/* Precio General (cuando customPrice es false) */}
              {!values.customPrice && (
                <div className="mb-6">
                  <FormInput
                    name="price"
                    label="Precio General"
                    type="number"
                    className="w-full"
                    onChange={(e) => {
                      const newPrice = e.target.value;
                      setFieldValue("price", newPrice);
                      // Actualizar precios de todas las categorías
                      values.categories.forEach((_, index) => {
                        setFieldValue(`categories.${index}.price`, newPrice);
                      });
                    }}
                  />
                </div>
              )}

              {/* Lista de Categorías */}
              <FieldArray name="categories">
                {({ remove }) => (
                  <div className="space-y-4">
                    {values.categories.map((category, index) => (
                      <div
                        key={index}
                        className="flex flex-col sm:flex-row items-center gap-3 p-4 bg-gray-50 rounded-xl transition-all duration-200 hover:bg-gray-100"
                      >
                        <div className="flex-1 w-full">
                          <FormInput
                            name={`categories.${index}.name`}
                            placeholder="Nombre de la categoría"
                            className="w-full"
                          />
                        </div>
                        {values.customPrice && (
                          <div className="w-full sm:w-32">
                            <FormInput
                              name={`categories.${index}.price`}
                              type="number"
                              placeholder="Precio"
                              className="w-full"
                            />
                          </div>
                        )}
                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="p-2 text-red-600 hover:bg-red-100 rounded-lg transition-all duration-200"
                        >
                          <Trash2 className="w-5 h-5" />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
            </div>

            {/* Ubicación */}
            <div className="bg-white shadow-lg rounded-xl p-6">
              <div className="flex justify-between items-center mb-6">
                <div className="flex items-center gap-2">
                  <MapPin className="w-6 h-6 text-gray-600" />
                  <h2 className="text-xl font-semibold text-gray-800">
                    Ubicación
                  </h2>
                </div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={addLocation}
                    onChange={(e) => handleAddLocation(e, setFieldValue)}
                    className="sr-only peer"
                  />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                </label>
              </div>
              {addLocation && (
                <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                  <GoogleMap
                    mapContainerClassName="w-full h-72 rounded-xl"
                    center={values.location}
                    zoom={15}
                    onClick={(e) =>
                      setFieldValue("location", {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      })
                    }
                  >
                    <Marker position={values.location} />
                  </GoogleMap>
                </LoadScript>
              )}
            </div>

            {/* Addons */}
            <div className="bg-white shadow-lg rounded-xl p-6">
              <h2 className="text-xl font-semibold text-gray-800 mb-6">
                Complementos
              </h2>
              <EventAddons
                addons={values.addons}
                setFieldValue={setFieldValue}
              />
            </div>

            {/* Form Actions */}
            <div className="flex flex-col sm:flex-row gap-4">
              <button
                type="button"
                onClick={() => navigate(-1)}
                className="w-full sm:w-1/3 py-4 px-6 bg-gray-100 text-gray-700 font-medium rounded-xl hover:bg-gray-200 transition-all duration-200 flex items-center justify-center gap-2"
              >
                Cancelar
              </button>
              <button
                type="submit"
                disabled={!isValid || isSubmitting}
                className="w-full sm:w-2/3 py-4 px-6 bg-blue-600 text-white font-medium rounded-xl hover:bg-blue-700 disabled:bg-gray-400 disabled:cursor-not-allowed transition-all duration-200 transform hover:scale-105 flex items-center justify-center gap-2"
              >
                {isSubmitting ? (
                  <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>
                    {event ? "Guardar cambios" : "Crear evento"}
                  </>
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EventsForm;