import React, { useState, useRef } from "react";
import { CalendarDays, Medal, Check } from "lucide-react";
import ConfettiExplosion from "react-confetti-explosion";

const CategoryStep = ({ categories, selectedCategory, onCategoryChange }) => {
  const [isExploding, setIsExploding] = useState(false);
  const [explosionPosition, setExplosionPosition] = useState({ x: 0, y: 0 });

  const explosionSoundRef = useRef(null);
  const ebikeSoundRef = useRef(null);

  const handleCategoryClick = (event, categoryId, categoryName) => {
    onCategoryChange(categoryId);

    const x = event.clientX;
    const y = event.clientY;
    setExplosionPosition({ x, y });
    setIsExploding(true);

    if (categoryName.toLowerCase().includes("e-bike")) {
      ebikeSoundRef.current.play();
    } else {
      explosionSoundRef.current.play();
    }

    setTimeout(() => setIsExploding(false), 2200);
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price);
  };

  // Sort categories by position
  const sortedCategories = [...categories].sort((a, b) => a.position - b.position);

  return (
    <div className="relative">
      {/* Audio components */}
      <audio
        ref={explosionSoundRef}
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Ffirework.mp3?alt=media&token=f7cb205d-f6a4-4833-8996-08407fdea0ce"
      />
      <audio
        ref={ebikeSoundRef}
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Fsi%20soy%20ama%20%5B%20ezmp3.cc%20%5D.mp3?alt=media&token=3072ab7f-22bd-434f-a56a-2d5333b3c17e"
      />

      {/* Confetti effect */}
      {isExploding && (
        <div
          className="absolute left-0 top-0 z-10 pointer-events-none"
          style={{
            left: explosionPosition.x,
            top: explosionPosition.y,
            transform: "translate(-50%, -50%)",
          }}
        >
          <ConfettiExplosion
            particleCount={200}
            duration={2500}
            force={0.6}
            width={1000}
          />
        </div>
      )}

      {/* Header */}
      <div className="bg-white p-4 rounded-lg shadow-sm mb-6">
        <h2 className="text-xl font-semibold flex items-center text-gray-800">
          <CalendarDays className="mr-2 h-6 w-6 text-blue-600" />
          Selecciona tu categoría
        </h2>
        <p className="text-gray-600 mt-2 text-sm">
          Elige la categoría que mejor se adapte a tu nivel y experiencia
        </p>
      </div>

      {/* Categories grid - Now using sortedCategories */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {sortedCategories.map((category) => (
          <div
            key={category.id}
            onClick={(e) => handleCategoryClick(e, category.id, category.name)}
            className={`group relative p-6 border-2 rounded-xl cursor-pointer transition-all duration-300 overflow-hidden
              ${selectedCategory === category.id 
                ? "border-blue-500 bg-blue-50" 
                : "border-gray-200 hover:border-blue-300 hover:bg-gray-50"
              }
              transform hover:-translate-y-1 hover:shadow-md`}
          >
            <div className="flex flex-col h-full">
              {/* Category name and medal icon */}
              <div className="flex justify-between items-start mb-3">
                <h3 className="text-lg font-semibold text-gray-800 group-hover:text-blue-600">
                  {category.name}
                </h3>
                <Medal className={`w-6 h-6 ${
                  selectedCategory === category.id ? "text-blue-500" : "text-gray-400"
                } group-hover:text-blue-500`} />
              </div>

              {/* Price display */}
              <div className="mt-auto">
                <p className={`text-xl font-bold ${
                  selectedCategory === category.id ? "text-blue-600" : "text-gray-700"
                } group-hover:text-blue-600`}>
                  {formatPrice(category.price)}
                </p>
                {category.description && (
                  <p className="text-sm text-gray-600 mt-1">
                    {category.description}
                  </p>
                )}
              </div>

              {/* Selected indicator */}
              {selectedCategory === category.id && (
                <div className="absolute bottom-0 right-0">
                  <div className="relative">
                    <div className="absolute bottom-0 right-0 w-16 h-16 bg-blue-500 transform rotate-45 translate-x-8 translate-y-8" />
                    <div className="absolute bottom-2 right-2 text-white">
                      <Check className="w-5 h-5" />
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Hover highlight effect */}
            <div className={`absolute inset-0 pointer-events-none transition-opacity duration-300
              ${selectedCategory === category.id ? 'opacity-10' : 'opacity-0'}
              group-hover:opacity-5 bg-blue-500`}
            />
          </div>
        ))}
      </div>

      {/* Help text */}
      <p className="text-sm text-gray-500 mt-4 text-center">
        Haz clic en una categoría para seleccionarla
      </p>
    </div>
  );
};

export default CategoryStep;