import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import {
  Calendar,
  Clock,
  MapPin,
  DollarSign,
  Users,
  Share2,
  Lock,
  MessageCircle,
  ChevronLeft,
} from "lucide-react";
import EventParticipants from "./EventParticipants";
import { eventsApi } from "../../lib/client";
import EventStats from "./EventStats";
import EventChat from "./EventChat";

const EventDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { user } = useUserAuth();
  const [event, setEvent] = useState(null);
  const [userIsParticipant, setUserIsParticipant] = useState(false);
  const [showRegisterWarning, setShowRegisterWarning] = useState(false);
  const [showSupportBubble, setShowSupportBubble] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      const eventData = await eventsApi.getEventById(id, {
        categories: true,
        creator: true,
        participants: true,
      });

      if (user && eventData.participants) {
        const isParticipant = eventData.participants.some(
          (p) => p.user.uid === user.uid
        );
        setUserIsParticipant(isParticipant);
      }

      setEvent(eventData);
    };

    fetchEventData();
  }, [id, user]);

  // Mostrar la burbuja después de un pequeño delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSupportBubble(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleJoinEvent = () => {
    if (!user) {
      setShowRegisterWarning(true);
      return;
    }
    navigate(`/payevent/${id}`);
  };

  const handleLeaveEvent = async () => {
    try {
      await eventsApi.removeParticipantFromEvent(id, user.uid);
      setUserIsParticipant(false);
    } catch (error) {
      console.error("Error leaving event:", error);
    }
  };

  const handleShare = () => {
    const eventUrl = window.location.href;
    const shareData = {
      title: event.eventName,
      text: `${event.eventName}\nFecha: ${new Date(
        event.dateTime
      ).toLocaleDateString()} a las ${new Date(
        event.dateTime
      ).toLocaleTimeString()}\nCosto: ${
        event.price > 0 ? `$${event.price}` : "Gratis"
      }`,
      url: eventUrl,
    };

    if (navigator.share) {
      navigator
        .share(shareData)
        .catch((error) => console.error("Error sharing:", error));
    } else {
      navigator.clipboard.writeText(
        `${shareData.title}\n${shareData.text}\n${shareData.url}`
      );
      alert("¡Enlace y detalles copiados al portapapeles!");
    }
  };

  const handleSupport = () => {
    setShowSupportBubble(false);
    const eventUrl = window.location.href;
    const whatsappUrl = `https://wa.me/526622995258?text=Hola!%20Necesito%20soporte%20en%20${eventUrl}%20!!!`;
    window.open(whatsappUrl, "_blank");
  };

  const ActionButtons = ({ className = "" }) => (
    <div className={`flex gap-3 ${className}`}>
      <button
        onClick={userIsParticipant ? handleLeaveEvent : handleJoinEvent}
        className={`flex-1 py-3 px-4 rounded-xl font-medium transition-colors ${
          userIsParticipant
            ? "bg-red-500 hover:bg-red-600 text-white"
            : "bg-gray-900 hover:bg-gray-800 text-white"
        }`}
        disabled={event.closed}
      >
        {userIsParticipant ? "Salir" : "Unirse"}
      </button>
      <div className="relative">
        <button
          onClick={handleSupport}
          className="flex-none py-3 px-4 bg-green-500 hover:bg-green-600 text-white rounded-xl font-medium transition-colors relative"
        >
          <MessageCircle className="w-5 h-5 fill-current" />
        </button>
        {/* Burbuja de soporte animada */}
        {showSupportBubble && (
          <div className="absolute bottom-full right-0 mb-2 transform -translate-y-1 animate-fadeIn">
            <div className="relative bg-white text-gray-800 rounded-xl px-4 py-2 shadow-lg text-sm whitespace-nowrap">
              <span>¿Necesitas soporte?</span>
              {/* Triángulo de la burbuja */}
              <div className="absolute bottom-0 right-4 transform translate-y-full">
                <div className="border-8 border-transparent border-t-white"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  if (!event) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );
  }

  return (
    <div className="relative bg-gray-50 min-h-screen">
      {/* Header */}
      <div className="bg-white">
        <div className="max-w-7xl mx-auto px-4 py-3">
          <div className="flex items-center justify-between">
            <button
              onClick={() => navigate(-1)}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <ChevronLeft className="w-6 h-6" />
            </button>
            <h1 className="text-lg font-semibold truncate">
              {event.eventName}
            </h1>
            <button
              onClick={handleShare}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <Share2 className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto">
        {/* Hero Section */}
        <div className="relative w-full aspect-video md:aspect-[21/9] overflow-hidden bg-gray-100">
          <img
            src={event.photo}
            alt={event.eventName}
            className="w-full h-full object-cover"
          />
        </div>

        {/* Content Sections */}
        <div className="px-4 -mt-6 relative z-10">
          <div className="bg-white rounded-t-3xl shadow-lg p-6">
            {/* Event Status */}
            {event.closed && (
              <div className="inline-flex items-center gap-2 bg-red-100 text-red-700 px-4 py-2 rounded-full mb-4">
                <Lock className="w-4 h-4" />
                <span className="text-sm font-medium">
                  Inscripciones cerradas
                </span>
              </div>
            )}

            {/* Title and Price */}
            <div className="flex justify-between items-start mb-4">
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                {event.eventName}
              </h1>
              {event.price > 0 && (
                <span className="bg-gray-900 text-white px-4 py-1 rounded-full text-lg font-semibold">
                  ${event.price}
                </span>
              )}
            </div>

            {/* Organizer Info */}
            <OrganizerInfo creator={event.creator} />

            {/* Quick Info Cards */}
            <div className="grid grid-cols-2 gap-3 my-6">
              <InfoCard
                icon={<Calendar className="w-5 h-5" />}
                label="Fecha"
                value={new Date(event.dateTime).toLocaleDateString()}
              />
              <InfoCard
                icon={<Clock className="w-5 h-5" />}
                label="Hora"
                value={new Date(event.dateTime).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              />
              <InfoCard
                icon={<MapPin className="w-5 h-5" />}
                label="Ubicación"
                value="Ver en mapa"
                className="col-span-2 cursor-pointer hover:bg-gray-50"
              />
            </div>

            {/* Action Buttons */}
            <div className="my-6">
              <ActionButtons />
            </div>

            {/* Description */}
            <div className="mb-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-2">
                Descripción
              </h2>
              <p className="text-gray-700 leading-relaxed">
                {event.description}
              </p>
            </div>

            {/* Participants Preview */}
            <div className="mb-6">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <Users className="w-5 h-5 text-gray-700" />
                  <h2 className="text-lg font-semibold text-gray-900">
                    Participantes
                  </h2>
                </div>
                <span className="text-sm text-gray-500">
                  {event.participants.length} inscritos
                </span>
              </div>
              <EventParticipants
                participants={event.participants.map((p) => ({
                  id: p.id,
                  joinedAt: p.joinedAt,
                  category: event.categories.find((c) => c.id === p.categoryId)
                    ?.name,
                  user: p.user,
                }))}
              />
            </div>

            {/* Chat Section */}
            <div className="mb-6">
              <EventChat uid={id} />
            </div>

            {/* Stats Section */}
            <EventStats
              latitude={event.location.lat}
              longitude={event.location.lng}
              eventDate={event.dateTime}
            />
          </div>
        </div>
      </div>

      {/* Register Dialog */}
      {showRegisterWarning && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-2xl max-w-md w-full p-6 m-4">
            <h3 className="text-xl font-semibold text-center text-gray-900 mb-4">
              Bienvenido
            </h3>
            <p className="text-gray-600 text-center mb-6">
              Regístrate o inicia sesión para unirte o pagar por el evento.
            </p>
            <div className="flex flex-col gap-3">
              <button
                onClick={() => navigate("/login")}
                className="w-full py-3 px-4 bg-gray-900 text-white rounded-xl font-medium hover:bg-gray-800 transition-colors"
              >
                Iniciar sesión
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="w-full py-3 px-4 border border-gray-300 text-gray-700 rounded-xl font-medium hover:bg-gray-50 transition-colors"
              >
                Crear cuenta
              </button>
              <button
                onClick={() => setShowRegisterWarning(false)}
                className="w-full py-3 px-4 text-gray-500 hover:bg-gray-100 transition-colors rounded-xl font-medium"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OrganizerInfo = ({ creator }) => (
  <div className="flex items-center space-x-4 mb-6">
    <div className="relative w-12 h-12">
      <img
        src={creator?.photoUrl}
        alt={creator?.name}
        className="rounded-full object-cover w-full h-full"
      />
    </div>
    <div>
      <p className="text-sm text-gray-500">Organizado por</p>
      <p className="font-medium text-gray-900">
        {creator?.name || "Organizador"}
      </p>
    </div>
  </div>
);

const InfoCard = ({ icon, label, value, className = "" }) => (
  <div
    className={`flex items-start space-x-3 p-4 bg-gray-50 rounded-xl ${className}`}
  >
    <div className="text-gray-700">{icon}</div>
    <div>
      <p className="text-sm text-gray-500">{label}</p>
      <p className="font-medium text-gray-900">{value}</p>
    </div>
  </div>
);

export default EventDetail;
